import { Card, Col, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDatetime } from '../../../services/utils';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../../../context/Context';
import { DISCOUNT_TYPE_SHIPPING_PERCENTAGE, USER_PERMISSION_DISCOUNT } from '../../../services/constants';
import TaxDetails from '../../../components/TaxDetails';

export const TotalWidget = ({ order }) => {
  const { profile } = useContext(AppContext);

  return (
    <Card className="mb-3 animate__animated animate__fadeIn animate__delay-2">
      <Card.Header>
        <div className="text-center">
          <h3>
            <CurrencyFormat
              id="total"
              decimalScale={2}
              fixedDecimalScale={true}
              value={order?.total ? order?.total : 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </h3>
          <h6>Order Amount</h6>
        </div>
      </Card.Header>

      {/*<ToggleCard title= open={true}>*/}
      <Card.Body className="bg-light">
        <div className="row">
          <div className="col-sm-12">
            <Row className="mb-3 pb-1 g-3">
              <Col className="col">
                <div className="h6 pb-0 mb-0 subtotal-label total-line-height">Subtotal</div>
              </Col>
              <Col className="col-auto">
                <div className="h6 pb-0 mb-0 text-end total-line-height">
                  <CurrencyFormat
                    id="subtotal"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={order?.subtotal ? order?.subtotal : 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </div>
              </Col>
            </Row>
            {order?.donation ? (
              <Row className="mb-3 pb-1 g-3 d-flex align-items-end">
                <Col>
                  <div className="h6 pb-0 mb-0 shipping-label">
                    <span className="total-line-height">Donation</span>
                  </div>
                </Col>
                <Col className="col-auto">
                  <div className="h6 pb-0 mb-0 text-end total-line-height">
                    <CurrencyFormat
                      id="donation-cost"
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={order?.donation ?? 0}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </div>
                </Col>
              </Row>
            ) : null}
            {order?.discount_cost > 0 || order?.discounts?.length > 0 ? (
              <div className="mb-3">
                <Row className="mb-1 d-flex flex-nowrap align-items-center">
                  <Col>
                    <div className="discount-is-giftcard h6 total-line-height pb-0 mb-0">
                      <span>Discount/Gift Card</span>
                    </div>
                  </Col>
                  <Col className="col-auto">
                    {order?.discount_cost > 0 ? (
                      <div className="h6 total-line-height pb-0 mb-0 text-end text-danger">
                        -
                        <CurrencyFormat
                          id="discount-cost"
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={order?.discount_cost}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </div>
                    ) : null}
                  </Col>
                </Row>
                {order?.discounts?.length > 0 ? (
                  <Row className="align-items-end">
                    <Col className="col-12 block-overflow">
                      {order.discounts.map((discount) => (
                        <div key={discount.id}>
                          {profile.permissions.includes(USER_PERMISSION_DISCOUNT) ? (
                            <Link
                              to={discount.is_gift_card ? `/gift-card/${discount.id}/` : `/discount/${discount.id}/`}
                              target="_blank"
                            >
                              <small>
                                <FontAwesomeIcon icon="fa-solid fa-tags" className="ms-2 me-1" />
                                <span className="discount-code">{discount.code}</span>
                              </small>
                            </Link>
                          ) : (
                            <small>
                              <FontAwesomeIcon icon="fa-solid fa-tags" className="ms-2 me-1" />
                              <span className="discount-code">{discount.code}</span>
                            </small>
                          )}
                        </div>
                      ))}
                    </Col>
                  </Row>
                ) : null}
              </div>
            ) : null}
            <Row className="mb-3 pb-1 g-3 d-flex align-items-end">
              <Col>
                <div className="h6 pb-0 mb-0 shipping-label">
                  <span className="total-line-height">
                    Shipping <small>{order?.override_shipping_cost !== null ? '(override)' : ''}</small>
                  </span>
                </div>
              </Col>
              <Col className="col-auto">
                <div className="h6 pb-0 mb-0 text-end total-line-height d-flex">
                  <div className="">
                    <CurrencyFormat
                      id="shipping-cost"
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={order?.shipping_cost ? order.shipping_cost : 0}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </div>
                  {order?.discounts?.some((discount) => discount.type === DISCOUNT_TYPE_SHIPPING_PERCENTAGE) && (
                    <span className="ms-2 text-decoration-line-through">
                      <CurrencyFormat
                        id="shipping-cost-original"
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={order?.shipping_cost_original ? order.shipping_cost_original : 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </span>
                  )}
                </div>
              </Col>
            </Row>

            {order?.service_fee !== 0 && (
              <Row className="mb-3 pb-1 g-3">
                <Col className="engraving-label">
                  <div className="h6 pb-0 mb-0 total-line-height">Service Fee</div>
                </Col>
                <Col className="col-auto">
                  <div className="h6 pb-0 mb-0 text-end total-line-height">
                    <CurrencyFormat
                      id="service-fee"
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={order?.service_fee ? order.service_fee : 0}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {order?.engraving_total !== 0 && (
              <Row className="mb-3 pb-1 g-3">
                <Col className="engraving-label">
                  <div className="h6 pb-0 mb-0 total-line-height">Engraving price</div>
                </Col>
                <Col className="col-auto">
                  <div className="h6 pb-0 mb-0 text-end total-line-height">
                    <CurrencyFormat
                      id="engraving-total"
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={order?.engraving_total ? order.engraving_total : 0}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </div>
                </Col>
              </Row>
            )}
            <Row className="mb-3 pb-1 g-3 align-items-end">
              <div className="h6 pb-0 mb-0 tax-label total-line-height">
                <TaxDetails order={order} useStrongTag={false} />
              </div>
            </Row>
          </div>
          <small className="text-center text-muted pb-0 mb-0">{formatDatetime(order?.created_at)}</small>
        </div>
      </Card.Body>
    </Card>
  );
};

TotalWidget.propTypes = {
  order: PropTypes.any
};
