import { ENVIRONMENT_TYPE } from '../utils';
export const DISCOUNT_TYPE_PRODUCT_FIXED_AMOUNT = 0;
export const DISCOUNT_TYPE_PRODUCT_PERCENTAGE = 1;
export const DISCOUNT_TYPE_CART_FIXED_AMOUNT = 2;
export const DISCOUNT_TYPE_CART_PERCENTAGE = 3;
export const DISCOUNT_TYPE_FREE_SHIPPING = 4;
export const DISCOUNT_TYPE_FREE_ENGRAVING = 5;
export const DISCOUNT_TYPE_SHIPPING_PERCENTAGE = 6;
export const DISCOUNT_TYPE_BUY_X_GET_Y = 7;
export const DISCOUNT_PERCENTAGE = [
  DISCOUNT_TYPE_PRODUCT_PERCENTAGE,
  DISCOUNT_TYPE_CART_PERCENTAGE,
  DISCOUNT_TYPE_SHIPPING_PERCENTAGE
];
export const DISCOUNT_FIXED_AMOUNT = [DISCOUNT_TYPE_CART_FIXED_AMOUNT, DISCOUNT_TYPE_PRODUCT_FIXED_AMOUNT];

export const DISCOUNT_MINIMUM_REQUIREMENT_PURCHASE = 0;
export const DISCOUNT_MINIMUM_REQUIREMENT_QUANTITY = 1;
export const DISCOUNT_NO_MINIMUM_REQUIREMENTS = 2;
export const DISCOUNT_ELIGIBILITY_EVERYONE = 0;
export const DISCOUNT_ELIGIBILITY_NEW_CUSTOMERS = 1;
export const DISCOUNT_ELIGIBILITY_BIRTHDAY = 2;

export const CUSTOMER_ELIGIBILITY = [
  {
    value: DISCOUNT_ELIGIBILITY_EVERYONE,
    label: 'Everyone'
  },
  { value: DISCOUNT_ELIGIBILITY_NEW_CUSTOMERS, label: 'New Customers' },
  { value: DISCOUNT_ELIGIBILITY_BIRTHDAY, label: 'Only On Birthday' }
];
export const BXGY_FREE_ITEM_VISIBLE = 0;
export const BXGY_FREE_ITEM_HIDDEN = 1;

export const BXGY_FREE_ITEM_VISIBILITY = [
  { value: BXGY_FREE_ITEM_VISIBLE, label: 'Item Visible' },
  { value: BXGY_FREE_ITEM_HIDDEN, label: 'Item Hidden' }
];

export const USER_TYPE_ADMIN = 'admin';
export const USER_TYPE_BRAND = 'brand';
export const USER_TYPE_AFFILIATE = 'affiliate';
export const USER_TYPE_CUSTOMER_SERVICE = 'customer_service';

export const USER_PERMISSION_PRODUCT = 'product';
export const USER_PERMISSION_PRODUCT_EDIT = 'product_edit';
export const USER_PERMISSION_BRAND = 'brand';
export const USER_PERMISSION_BRAND_EDIT = 'brand_edit';
export const USER_PERMISSION_DISCOUNT = 'discount';
export const USER_PERMISSION_DISCOUNT_EDIT = 'discount_edit';
export const USER_PERMISSION_ORDER = 'order';
export const USER_PERMISSION_ORDER_EDIT = 'order_edit';
export const USER_PERMISSION_SUBSCRIPTION = 'subscription';
export const USER_PERMISSION_ACCOUNT = 'account';
export const USER_PERMISSION_ACCOUNT_EDIT = 'account_edit';
export const USER_PERMISSION_SETTING = 'setting';
export const USER_PERMISSION_SETTING_EDIT = 'setting_edit';

export const USER_PERMISSION_GIFT_CARD = 'gift_card';
export const USER_PERMISSION_REPORT = 'report';

export const USER_PERMISSION_GIFT_CARD_EDIT = 'gift_card_edit';

export const USER_PERMISSION_CUSTOMER = 'customer';
export const USER_PERMISSION_CUSTOMER_EDIT = 'customer_edit';

export const USER_PERMISSION_DASHBOARD = 'dashboard';
export const USER_PERMISSION_EXPORT = 'export';
export const USER_PERMISSION_IMPORT = 'import';
export const USER_PERMISSION_IMPORT_EDIT = 'import_edit';
export const USER_PERMISSION_DONATION = 'donation';

export const EXPORT_MAX_EMPTY_COUNT = ENVIRONMENT_TYPE === 'production' ? 3000 : 100;

export const RECOVERY_STATUS_NOT_RECOVERED = 'not-recovered';
export const RECOVERY_STATUS_RECOVERED = 'recovered';

export const ADMIN_OR_BRAND = [USER_TYPE_ADMIN, USER_TYPE_BRAND];

export const ALLOWED_TAGS = [
  'em',
  'u',
  'strong',
  'p',
  'br',
  'span',
  'a',
  'table',
  'td',
  'tr',
  'i',
  'li',
  'ul',
  'tbody',
  'figure',
  'thead',
  'th',
  'ol',
  'blockquote',
  'h1',
  'h2',
  'h3',
  'h4',
  'img'
];
export const ALLOWED_ATTRS = {
  '*': ['style'],
  a: ['href', 'target'],
  img: ['src']
};

export const TIME_ZONE = 'America/Los_Angeles';

export const BRAND_TRIGGER_DELAY_OPTIONS = [
  { value: 1, label: 'One hour' },
  { value: 6, label: 'Six hours' },
  { value: 12, label: 'Twelve hours' }
];
export const BRAND_PRIORITY_OPTIONS = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' }
];

export const GOOGLE_CATEGORY_CHOICES = [
  { value: 1604, label: 'Apparel & Accessories > Clothing' },
  { value: 5322, label: 'Apparel & Accessories > Clothing > Activewear' },
  { value: 3951, label: 'Apparel & Accessories > Clothing > Activewear > American Football Pants' },
  { value: 5697, label: 'Apparel & Accessories > Clothing > Activewear > Bicycle Activewear' },
  { value: 5378, label: 'Apparel & Accessories > Clothing > Activewear > Boxing Shorts' },
  { value: 5460, label: 'Apparel & Accessories > Clothing > Activewear > Hunting Clothing' },
  { value: 5379, label: 'Apparel & Accessories > Clothing > Activewear > Martial Arts Shorts' },
  { value: 5517, label: 'Apparel & Accessories > Clothing > Activewear > Motorcycle Protective Clothing' },
  { value: 5555, label: 'Apparel & Accessories > Clothing > Activewear > Paintball Clothing' },
  { value: 2271, label: 'Apparel & Accessories > Clothing > Dresses' },
  { value: 5182, label: 'Apparel & Accessories > Clothing > One-Pieces' },
  { value: 203, label: 'Apparel & Accessories > Clothing > Outerwear' },
  { value: 7313, label: 'Apparel & Accessories > Clothing > Outfit Sets' },
  { value: 204, label: 'Apparel & Accessories > Clothing > Pants' },
  { value: 212, label: 'Apparel & Accessories > Clothing > Shirts & Tops' },
  { value: 207, label: 'Apparel & Accessories > Clothing > Shorts' },
  { value: 1581, label: 'Apparel & Accessories > Clothing > Skirts' },
  { value: 5344, label: 'Apparel & Accessories > Clothing > Skorts' },
  { value: 1594, label: 'Apparel & Accessories > Clothing > Suits' },
  { value: 211, label: 'Apparel & Accessories > Clothing > Swimwear' },
  { value: 5388, label: 'Apparel & Accessories > Clothing > Traditional & Ceremonial Clothing' },
  { value: 213, label: 'Apparel & Accessories > Clothing > Underwear & Socks' },
  { value: 2306, label: 'Apparel & Accessories > Clothing > Uniforms' },
  { value: 167, label: 'Apparel & Accessories > Clothing Accessories' },
  { value: 187, label: 'Apparel & Accessories > Shoes' },
  { value: 412, label: 'Food, Beverages & Tobacco' },
  { value: 413, label: 'Food, Beverages & Tobacco > Beverages' },
  { value: 499676, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages' },
  { value: 414, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Beer' },
  { value: 7486, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Bitters' },
  { value: 5725, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Cocktail Mixes' },
  {
    value: 543537,
    label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Cocktail Mixes > Frozen Cocktail Mixes'
  },
  {
    value: 543536,
    label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Cocktail Mixes > Shelf-stable Cocktail Mixes'
  },
  { value: 5887, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Flavored Alcoholic Beverages' },
  { value: 6761, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Hard Cider' },
  { value: 417, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits' },
  { value: 505761, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Absinthe' },
  { value: 2364, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Brandy' },
  { value: 1671, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Gin' },
  { value: 2933, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Liqueurs' },
  { value: 2605, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Rum' },
  {
    value: 502976,
    label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Shochu & Soju'
  },
  {
    value: 543642,
    label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Shochu & Soju > Shochu'
  },
  {
    value: 543643,
    label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Shochu & Soju > Soju'
  },
  { value: 2220, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Tequila' },
  { value: 2107, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Vodka' },
  { value: 1926, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Whiskey' },
  { value: 421, label: 'Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Wine' },
  { value: 6797, label: 'Food, Beverages & Tobacco > Beverages > Buttermilk' },
  { value: 6848, label: 'Food, Beverages & Tobacco > Beverages > Powdered Beverage Mixes' }
];

export const TIME_UNITS = [
  { unit: 'year', ms: 31536000000 },
  { unit: 'month', ms: 2592000000 },
  { unit: 'day', ms: 86400000 },
  { unit: 'hour', ms: 3600000 },
  { unit: 'minute', ms: 60000 },
  { unit: 'second', ms: 1000 }
];
